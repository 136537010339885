import { NavLink } from 'react-router-dom';

import "../static/css/WrongPlaceView.css";

function WrongPlaceView() {
    return (
        <div className="container main-container text-center text-wrap text-break">
            <h1>Page not found<br/><a href="https://www.youtube.com/watch?v=JdCrnBLchro">Error 404</a></h1>
            <h4>Are you lost?</h4>
            <NavLink className="col-4 mx-auto btn btn-outline-light" to="/main">
                Let me show you the main page 😊
            </NavLink>
        </div>
    );
}

export default WrongPlaceView;