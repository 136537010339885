import { NavLink } from 'react-router-dom';

import ImageComponent from './ImageComponent';

function ProjectComponent(props) {
    const app = props.app;
    
    const getLinkFromApp = (getLinkApp) => {
        return JSON.parse(getLinkApp.links)[0].links[0].href;
    };

    if (app.slug === null) {
        return (
            <div className="col-sm-6 col-12 mb-5 justify-content-center" key={ app.id }>
                <a className="project_item" href={ getLinkFromApp(app) }>
                    <h3 className="text-center text-wrap">{ app.name }</h3>
                    <span className="justify-content-center"><ImageComponent imgName={ app.image } className="p-0 mb-5 project-image" alt={ app.name + " icon" } /></span>
                </a>
            </div>
        );
    } else {
        return (
            <div className="col-sm-6 col-12 mb-5 justify-content-center" key={ app.id }>
                <NavLink className="project_item" to={ "/app/" + app.slug }>
                    <h3 className="text-center text-wrap">{ app.name }</h3>
                    <span className="justify-content-center"><ImageComponent imgName={ app.image } className="p-0 mb-5 project-image" alt={ app.name + " icon" } /></span>
                </NavLink>
            </div>
        );
    }
  }
  
export default ProjectComponent;