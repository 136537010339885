import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import axios from 'axios';
import ReactMarkdown from 'react-markdown';
import GFM from 'remark-gfm';

import Spinner from './Spinner';

import "../static/css/PolicyView.css";

function PolicyView(props) {
    const [policyName, setPolicyName] = useState(null);
    const [policy, setPolicy] = useState(null);

    const history = useHistory();

    useEffect(() => {
        const policyName = props.match.params.policy;

        const fUrl = 'https://marvin.tech/api/projects/policy.php?of=' + policyName;
        axios.get(fUrl)
            .then(res => {
                if (res.data === 0) {
                    history.push("/lost");
                } else {
                    setPolicyName(res.data.name);
                    setPolicy(res.data.text);
                }
            })
            .catch(e => console.log(e));
            
    }, [props.match.params.policy, history]);

    if (policy === null) {
        return (
            <Spinner />
        );
    }
    return (
        <div className="container-fluid main-container">
            <h2 className="policy-title text-center text-wrap text-break">{ "Privacy Policy of " + policyName }</h2>
            <div className="policy-view">
                <div className="container policy-markdown">
                    <ReactMarkdown plugins={ [GFM] } children={ policy } />
                </div>
            </div>
        </div>
    );
}

export default PolicyView;