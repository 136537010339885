import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import axios from 'axios';
import ReactMarkdown from 'react-markdown';
import GFM from 'remark-gfm';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library as faLibrary } from '@fortawesome/fontawesome-svg-core';
import { faCloudDownloadAlt } from '@fortawesome/free-solid-svg-icons';

import Spinner from './Spinner';
import ImageComponent from './ImageComponent'

import "../static/css/ProjectView.css";

faLibrary.add(faCloudDownloadAlt);

function ProjectView(props) {
    const [appSlug, setAppSlug] = useState(null);
    const [appName, setAppName] = useState(null);
    const [appDescription, setAppDescription] = useState(null);
    const [appImage, setAppImage] = useState(null);
    const [appLinks, setAppLinks] = useState(null);

    const history = useHistory();

    useEffect(() => {
        const newAppSlug = props.match.params.app;
        const fetchApp = (slug) => {   
            const fUrl = 'https://marvin.tech/api/projects/get.php?what=' + slug;
            axios.get(fUrl)
                .then(res => {
                    if (res.data === 0) {
                        history.push("/lost");
                    } else {
                        setAppName(res.data.name);
                        setAppLinks(JSON.parse(res.data.links)[0].links);
                        setAppImage(res.data.image);
                        setAppDescription(res.data.description);
                    }
                })
                .catch(e => console.log(e));
        };

        if (appSlug !== newAppSlug) {
            setAppSlug(newAppSlug);
            fetchApp(newAppSlug);
        }
    }, [props.match.params.app, appSlug, history]);

    if (appDescription === null) {
        return (
            <Spinner />
        );
    }
    return (
        <div className="container main-container">
            <div className="row">
                <h2 className="text-center text-wrap">{ appName }</h2>
            </div>
            <div className="row">
                <div className="col-sm-3 col-12">
                    <ImageComponent imgName={ appImage } className="app-img mx-0 mb-5 project-image" alt={ appName + " icon" } />
                </div>
                <div className="col-sm-9 col-12">
                    <ReactMarkdown className="text-left text-wrap text-break" plugins={ [GFM] } children={ appDescription } />
                </div>
            </div>
            <div className="row btns">
            {
            appLinks.map(link =>
                <div className="row" key={ link.title }>
                    <div className="text-center text-wrap">
                        <a className="btn btn-outline-light btn-lg" href={ link.href }>
                        <div className="icon baseline">
                            <FontAwesomeIcon icon={ faCloudDownloadAlt } />
                        </div>
                        { link.title }
                        </a>
                    </div>
                </div>
            )
            }
            </div>
        </div>
    );
}

export default ProjectView;