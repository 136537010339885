import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library as faLibrary } from '@fortawesome/fontawesome-svg-core';
import { faSyncAlt } from '@fortawesome/free-solid-svg-icons';

import "../static/css/Spinner.css";

faLibrary.add(faSyncAlt);

function Spinner() {
    return (
        <div className="container">
            <div className="row end_margin">
                <span className="align-self-center text-center">
                    <FontAwesomeIcon icon={ faSyncAlt } className="spinner" />
                </span>
            </div>
        </div>
    );
  }
  
export default Spinner;