import React, { useState, useRef, useEffect } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";

import { useTranslation } from 'react-i18next';
import i18n from "i18next";

import "../../static/css/iDays.css";

function NamedayView(props) {
    const { t } = useTranslation();

    const idaysIcon = props.idaysIcon;

    const [name, setName] = useState(null);
    const [date, setDate] = useState(null);
    const [error, setError] = useState(null);

    const history = useHistory();

    const idaysRef = useRef(null);
    
    useEffect(() => {
        idaysRef.current.scrollIntoView();
    }, []);

    const handleFormSubmit = (e) => {
        e.preventDefault();

        axios({
        method: "post",
        url: 'https://marvin.tech/api/contact/contact.php',
        headers: { "content-type": "application/json" },
        data: { type: "nameday", name: name, date: date, locale: i18n.language }
        })
        .then(result => {
            if (result.data.sent) {
                if (i18n.language === 'idays') {
                    history.push('/idays/thanks');
                } else {
                    history.push('/'+i18n.language+'/idays/thanks');
                }
            } else {
                setError(result.data);
            }
        })
        .catch(err => setError(err.message));
    };

    const handleChange = (e, field) => {
        if (field === "name") {
            setName(e.target.value);
        } else if (field === "date") {
            setDate(e.target.value);
        }
    };

    const padZero = (n) => {
        return (n < 10 && n >= 0) ? ('0' + n) : n.toString();
    }

    const todayDate = () => {
        const today = new Date();
        const tDay = padZero(today.getUTCDate());
        const tMonth = padZero(today.getMonth() + 1);
        const tYear = today.getUTCFullYear();
        return tYear +"-"+ tMonth +"-"+ tDay;
    };

    return (
        <div className="container-fluid min-vh-100 idays" ref={ idaysRef }>
            <div className="row g-0 navbar-yellow">
                <span className="align-self-center text-center text-wrap align-middle">
                    { idaysIcon }
                    <h3 className="d-inline-block align-text-center">iDays</h3>
                </span>
            </div>

            <div className="container">
                <h1>{ t("Missing Namedays") }</h1>
                <form method="post">
                    <div className="form-floating mb-3">
                        <input id="floatingInput" className="form-control" placeholder="Marvin" required={ true } type="text" onChange={ e => handleChange(e, "name") } />
                        <label htmlFor="floatingInput">{ t("Name") }</label>
                    </div>
                    <div className="form-floating mb-3">
                        <input id="floatingTextarea" className="form-control" placeholder="31.12.2021" required={ true } type="date" defaultValue={ todayDate() } onChange={ e => handleChange(e, "date") } />
                        <label htmlFor="floatingTextarea">{ t("Date") }</label>
                    </div>
                    <div className="row">
                        <input className="col-lg-2 col-sm-3 col-9 mx-auto btn btn-outline-dark btn-lg" type="submit" onClick={ e => handleFormSubmit(e) } value={ t("Submit") } />
                        { error && <div className="error">{ error }</div> }
                    </div>
                </form>
            </div>
        </div>
    );
}

export default NamedayView;