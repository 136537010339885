import { useHistory } from "react-router-dom";
import i18n from "i18next";

import ImageComponent from '../ImageComponent'
import NamedayView from './NamedayView';
import ThanksIDaysView from "./ThanksIDaysView";
//import UpgradeView from './UpgradeView';

function IDays(props) {
    const history = useHistory();
    const idaysIcon = <ImageComponent imgName="idays.png" className="d-inline-block align-text-center" alt="iDays icon" width="60" height="60" />;

    function changeLanguage(lang) {
        i18n.changeLanguage(lang, () => {
            history.push(`/${i18n.language}/idays/namedays`);
        })
    }
    
    switch(props.match.params.route) {
        case "namedays":
            return <NamedayView idaysIcon={ idaysIcon } />;
        case "namedays-sk":
            changeLanguage("sk");
            return null;
        case "namedays-cs":
            changeLanguage("cs");
            return null;
        case "namedays-de":
            changeLanguage("de");
            return null;
        case "namedays-es":
            changeLanguage("es");
            return null;

        case "privacy-policy":
            history.push("/privacy-policy/idays");
            return null;

        case "thanks":
            return <ThanksIDaysView idaysIcon={ idaysIcon } />;
        /*case "e9KAkGOIB2ctHyEjhIdS":
            return <UpgradeView idaysIcon={ idaysIcon } />;*/

        default:
            history.push("/lost");
            return null;
      }
}

export default IDays;