import { NavLink } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library as faLibrary } from '@fortawesome/fontawesome-svg-core';
import { faBars } from '@fortawesome/free-solid-svg-icons';

import headerLogo from'../static/images/header.png';

faLibrary.add(faBars);
  
function Navbar(props) {
    const getLinkFromApp = (getLinkApp) => {
        return JSON.parse(getLinkApp.links)[0].links[0].href;
    };

    const appLink = (app) => {
        if (app.slug !== null) {
            return (
                <NavLink className="dropdown-item text-wrap text-break" to={ "/app/" + app.slug }>
                    <strong>{ app.name }</strong>
                </NavLink>
            );
        } else {
            return (
                <a className="dropdown-item text-wrap text-break" href={ getLinkFromApp(app) }>
                    <strong>{ app.name }</strong>
                </a>
            );
        }
    };

    return (
        <nav className="navbar navbar-expand-lg navbar-blackout flex-shrink-0">
        <div className="container">
            <div className="d-flex flex-grow-1">
                <NavLink className='navbar-brand d-inline-block' to='/'>
                    <img src={ headerLogo } alt="Header logo"/>
                </NavLink>
                <div className="w-100 d-flex align-items-center justify-content-end">
                    <button className="navbar-toggler m-3" type="button" data-bs-toggle="collapse" data-bs-target="#mainMenu" aria-controls="mainMenu" aria-expanded="false" aria-label="Toggle navigation">
                        <FontAwesomeIcon icon={ faBars } className="navbar-toggler-icon" />
                    </button>
                </div>
            </div>
            <div className="collapse navbar-collapse flex-grow-1 text-right" id="mainMenu">
                <ul className="navbar-nav ms-auto flex-nowrap">
                    <li className="nav-item dropdown">
                        <a className="nav-link menu-item dropdown-toggle" id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false" href="{% url 'main_site:index' %}">
                            <strong>My Projects</strong>
                        </a>
                        <ul className="dropdown-menu dropdown-menu-dark" aria-labelledby="navbarDropdownMenuLink">
                            {
                            props.apps.map(app =>
                                <li key={ app.id }>
                                    { appLink(app) }
                                </li>
                            )
                            }
                        </ul>
                    </li>
                        <li className="nav-item">
                        <NavLink className="nav-link menu-item" to="/contact">
                            <strong>Contact Me</strong>
                        </NavLink>
                        </li>
                </ul>
            </div>
        </div>
        </nav>
    );
};
  
export default Navbar;