import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import LanguageDetector from 'i18next-browser-languagedetector';

// the translations
// (tip move them in a JSON file and import them)
const resources = {
  en: {
    translation: {
      "Missing Namedays": "Missing Namedays",
      "Name": "Name",
      "Date": "Date",
      "Submit": "Submit",
      "Thank you for your submission": "Thank you for your submission",
      "Done": "Done"
    }
  },
  sk: {
    translation: {
      "Missing Namedays": "Chýbajúce Meniny",
      "Name": "Meno",
      "Date": "Dátum",
      "Submit": "Nahlásiť",
      "Thank you for your submission": "Ďakujeme vám za nahlásenie",
      "Done": "Hotovo"
    }
  },
  cs: {
    translation: {
      "Missing Namedays": "Chybějící Svátky",
      "Name": "Jméno",
      "Date": "Datum",
      "Submit": "Nahlásit",
      "Thank you for your submission": "Děkujeme vám za nahlášení",
      "Done": "Hotovo"
    }
  },
  de: {
    translation: {
      "Missing Namedays": "Fehlende Namenstage",
      "Name": "Name",
      "Date": "Datum",
      "Submit": "Einreichen",
      "Thank you for your submission": "Vielen Dank für Ihre Einreichung",
      "Done": "Erledigt"
    }
  },
  es: {
    translation: {
      "Missing Namedays": "Día del Santo Faltante",
      "Name": "Nombre",
      "Date": "Fecha",
      "Submit": "Entregar",
      "Thank you for your submission": "Gracias por su envío",
      "Done": "Hecho"
    }
  }
};

function getDefaults() {
    return {
      order: ['path', 'navigator'],
      lookupFromPathIndex: 0,
    };
}

i18n
    .use(LanguageDetector)
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        fallbackLng: 'en',

        interpolation: {
            escapeValue: false // react already safes from xss
        },

        detection: getDefaults()
    });

  export default i18n;