import React, { Component } from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import axios from 'axios';
import ReactGA from 'react-ga';

import './static/css/App.css';
import Navbar from './components/Navbar';
import MainView from './components/MainView';
import ProjectView from './components/ProjectView';
import PolicyView from './components/PolicyView';
import ContactView from './components/ContactView';
import ThanksView from './components/ThanksView';
import WrongPlaceView from './components/WrongPlaceView';
import IDays from './components/iDays/IDays';

class App extends Component {
  state = {
    apps: [],
    imgs: [],
    error: null
  }

  initializeReactGA() {
    ReactGA.initialize('G-C3ERFH669P');
    ReactGA.pageview('/main');
  }

  componentDidMount() {
      this.initializeReactGA();

      axios.get('https://marvin.tech/api/projects/get.php')
          .then(res => {
              const apps = res.data;
              this.setState({ apps: apps });
          })
          .catch(e => {
            console.log(e);
            this.setState({ error: e.message });
          });
  }

  render() {
    return (
      <Router>
      <div className="container-fluid min-vh-100 d-flex flex-column html-container">
      <Navbar apps={ this.state.apps } />

      { this.state.error != null &&
          <div className="text-center error">
            <h5 className="error">{ this.state.error }</h5>
          </div>
        }

      <div className="container-fluid d-flex flex-grow-1 main_site_bg">
        <Switch>
          <Route exact path='/' render={props => <MainView key={ this.state.apps.length } apps={ this.state.apps } {...props} />} />
          <Redirect exact from="/main" to="/" />
          <Route exact path='/app/:app' component={ ProjectView } />
          <Route exact path='/privacy-policy/:policy' component={ PolicyView } />
          <Route exact path='/contact' component={ ContactView } />
          <Route exact path='/contact/thanks' component={ ThanksView } />
          
          <Route exact path='/idays/:route' component={ IDays } />
          <Route exact path='/:lang/idays/:route' component={ IDays } />

          <Route exact path='/unpredictable/privacy-policy'><Redirect to="/privacy-policy/unpredictable" /></Route>
          
          <Route exact path='/lost' component={ WrongPlaceView } />
          <Redirect from="*" to="/lost" />
        </Switch>
      </div>
      </div>

      <footer className="row">       
        <span className="align-self-center text-center text-wrap">Marvin © 2015-{ new Date().getFullYear() }</span>
      </footer>
    </Router>
    );
  }
}

// <Route exact path='/oldappnewapp'><Redirect to="/idays/e9KAkGOIB2ctHyEjhIdS" /></Route>

export default App;
