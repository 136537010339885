import React, { useEffect, useState } from 'react';
import axios from 'axios'

function ImageComponent(props) {
    const imgName = props.imgName;
    const [imgData, setImgData] = useState(null);

    useEffect(() => {
        axios.get(
            'https://marvin.tech/api/projects/img.php?what=' + imgName,
            { responseType: 'arraybuffer' },
        )
        .then(response => {
            const base64 = btoa(
                new Uint8Array(response.data).reduce(
                    (data, byte) => data + String.fromCharCode(byte),
                    ''
                )
            );
            setImgData("data:;base64," + base64);
        });
    });

    if (imgData != null) {
        return <img className={ props.className } alt={ props.alt } src={ imgData } width={ props.width } height={ props.height } />;
    } else {
        return null;
    }
  }
  
export default ImageComponent;