import React, { useRef, useEffect } from "react";
import { NavLink } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import i18n from "i18next";

import "../../static/css/iDays.css";

function ThanksIDaysView(props) {
    const { t } = useTranslation();

    const idaysIcon = props.idaysIcon;

    const idaysRef = useRef(null);
    
    useEffect(() => {
        idaysRef.current.scrollIntoView();
    }, []);

    const linkBack = () => {
        if (i18n.language === 'idays') {
            return '/idays/namedays';
        } else {
            return '/'+i18n.language+'/idays/namedays';
        }
    };

    return (
        <div className="container-fluid min-vh-100 idays" ref={ idaysRef }>
        <div className="row g-0 navbar-yellow">
            <span className="align-self-center text-center text-wrap align-middle">
                { idaysIcon }
                <img src="" alt="" width="60" height="60" className="d-inline-block align-text-center" />
                <h3 className="d-inline-block align-text-center">iDays</h3>
            </span>
        </div>

        <div className="container text-center text-wrap text-break">
            <h1>{ t("Thank you for your submission") }</h1>
            <NavLink className="col-lg-2 col-sm-3 col-9 mx-auto btn btn-outline-dark" to={ linkBack() }>{ t("Done") }</NavLink>
        </div>
        </div>
    );
}

export default ThanksIDaysView;