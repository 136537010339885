import React, { useState } from 'react';

import Spinner from './Spinner';
import ProjectComponent from './ProjectComponent'

import "../static/css/MainView.css";

function MainView(props) {
    const [apps] = useState(props.apps);

    if (apps && apps.length) {
        return (
            <div className="container main-container">
                <div className="row mb-3">
                {
                apps.map(app => <ProjectComponent app={ app } />)
                }
                </div>
            </div>
        );
    } else {
        return (
            <Spinner />
        );
    }
  }
  
export default MainView;