import { NavLink } from 'react-router-dom';

import "../static/css/ThanksView.css";

function ThanksView() {
    return (
        <div className="container main-container text-center text-wrap text-break">
            <div className="m-3">
                <h1>Thank you for your message</h1>
                <h4>We will get in touch with you as soon as possible</h4>
            </div>
            <NavLink className="col-lg-1 col-sm-3 col-9 mx-auto btn btn-outline-light" to="/main">Done</NavLink>
        </div>
    );
}

export default ThanksView;