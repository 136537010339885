import React, { useState } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";

import "../static/css/ContactView.css";

function ContactView() {
    const [email, setEmail] = useState(null);
    const [message, setMessage] = useState(null);
    const [error, setError] = useState(null);

    const history = useHistory();
    
    const handleFormSubmit = (e) => {
        e.preventDefault();

        axios({
        method: "post",
        url: 'https://marvin.tech/api/contact/contact.php',
        headers: { "content-type": "application/json" },
        data: { type: "contact", email: email, message: message }
        })
        .then(result => {
            if (result.data.sent) {
                history.push("/contact/thanks");
            } else {
                setError(result.data);
            }
        })
        .catch(err => setError(err.message));
    };

    const handleChange = (e, field) => {
        if (field === "email") {
            setEmail(e.target.value);
        } else if (field === "msg") {
            setMessage(e.target.value);
        }
    };

    return (
        <div className="container main-container">
            <h1>Contact Me</h1>
            <form action="#">
                <div className="form-floating mb-3">
                    <input id="floatingInput" className="form-control" placeholder="contact@marvin.tech" required={ true } type="email" onChange={ e => handleChange(e, "email") } />
                    <label htmlFor="floatingInput">Email address</label>
                </div>
                <div className="form-floating mb-3">
                    <textarea id="floatingTextarea" className="form-control" placeholder="Leave your message here" required={ true } type="text" onChange={ e => handleChange(e, "msg") } />
                    <label htmlFor="floatingTextarea">Message</label>
                </div>
                <div className="row">
                <input className="col-lg-1 col-sm-3 col-9 mx-auto btn btn-outline-light btn-lg" type="submit" onClick={ e => handleFormSubmit(e) } value="Send" />
                { error && <div className="text-center error"><h5 className="error">{ error }</h5></div> }
                </div>
            </form>
        </div>
    );
}

export default ContactView;